import { Button as AntButton } from 'antd';
import type { ButtonProps as AntButtonProps } from 'antd';

export interface ButtonProps extends AntButtonProps {
  mobileSize?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { mobileSize, ...rest } = props;
  return (
    <AntButton
      shape="round"
      style={{ ...(mobileSize && { width: '64px', height: '64px' }) }}
      {...rest}
    />
  );
};
